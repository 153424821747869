export const getCancelConfirmModal = (state) => state.cancelConfirmModal;
export const getCancelConfirmModalSubscriptionId = (state) => getCancelConfirmModal(state).subscriptionId;
export const isCancelConfirmModalOpen = (state) => getCancelConfirmModal(state).isOpen;
export const getCancelConfirmModalSubscription = (state) =>
  getSubscriptionById(state, getCancelConfirmModalSubscriptionId(state));
export const getSubscriptions = (state) => state.subscriptions;
export const getSubscriptionById = (state, id) =>
  getSubscriptions(state).entities.find((subscription) => subscription.id === id);
export const isLoading = (state) => state.subscribtions.loading === 'pending';
export const getDetails = (state, subscriptionId) => state.details.entities[subscriptionId];
export const isDetailsOpen = (state, subscriptionId) => state.accordion.indexOf(subscriptionId) > -1;
export const isDetailsLoading = (state, subscriptionId) => state.details.loading.indexOf(subscriptionId) > -1;
export const getLanguage = (state) => state.language;
export const getRegionalSettings = (state) => state.regionalSettings;
